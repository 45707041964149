import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import Skel from "./skel";
import "./layout.scss";

const Layout = ({ children, extra = null, dark = true, noHeader = false }) => {
  return (
    <Skel dark={dark} noHeader={noHeader}>
      <main>
        <Container className="p-3">
          {children}
        </Container>
      </main>
      {extra ? extra : null}
    </Skel>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
