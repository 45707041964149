import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import FAQ from "../../components/faq";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { SIGNUP_URL } from "../../util/constants";
import {
  saveMarketingCookies,
  getMarketingUrlParams,
} from "../../util/cookies";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const GoTabIntegration = () => {
  const data = useStaticQuery(graphql`
    query {
      taplistioGotab: file(
        relativePath: { eq: "integrations/taplistio-gotab.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  useEffect(() => {
    const existingParams = getMarketingUrlParams(window.location);
    if (!existingParams.utm_source) {
      saveMarketingCookies({
        utm_source: "gotab",
        utm_campaign: "gotab-integration-page",
      });
    }
  }, []);

  const pageExtra = (
    <section className="gray-bg overflow-hidden" style={{ padding: 30 }}>
      <Row>
        <Col className="justify-content-center text-center">
          <p class="lead">
            Ready to give the integration a try? Get your account running in
            minutes.
          </p>
          <a className="m-btn m-btn-theme2nd m-btn-radius" href={SIGNUP_URL}>
            Get Started
          </a>
        </Col>
      </Row>
    </section>
  );

  return (
    <Layout extra={pageExtra}>
      <SEO
        title={"Digital TV Menus for GoTab in 1 click"}
        description={
          "Taplist.io integrates directly with GoTab for instantly-updating TV menu boards."
        }
      />
      <section className="section" style={{ paddingTop: 30 }}>
        {/* <Row>
          <Col md={3}>
            <Link to="/integrations">&laquo; Integrations</Link>
          </Col>
        </Row> */}
        <Row>
          <Col className="justify-content-center text-center">
            <h1>Digital TV Menus for GoTab</h1>
            <p className="lead">
              Taplist.io integrates directly with GoTab. Update your menu in
              GoTab and have it instantly show on TVs in your Restaurant, Bar,
              or Brewery.
            </p>
            <Img
              className="max-width-auto"
              fluid={data.taplistioGotab.childImageSharp.fluid}
              loading="eager"
              fadeIn={false}
              alt="A GoTab menu being shown on a full-screen TV."
            />
          </Col>
        </Row>
      </section>
      <section>
        <Row>
          <Col md={4}>
            <h2>What it does</h2>
          </Col>
          <Col md={8}>
            <p className="lead">
              When you activate the GoTab Integration in your Taplist.io
              account, we'll <b>automatically and continuously sync</b> your
              GoTab menus and products into Taplist.io, and show them on your
              TVs.
            </p>
            <p className="lead">Here's what you can expect:</p>
            <ul>
              <li>
                <b>Instant TV menu updates with no extra training.</b> After you
                set things up, there are no extra changes to your staff's
                workflow. Changes you make to your existing GoTab menus will
                instantly propagate to your Taplist.io TV screens.
              </li>
              <li>
                <b>Full support for multiple TVs.</b> Show everything on a
                single TV, or install and link multiple TVs throughout your
                location.
              </li>
              <li>
                <b>Highly customizable with no designer required.</b> Make your
                menus stand out and attract more business by styling them
                however you like. Choose from our library of built-in HD
                backgrounds, fonts, illustrations, and more.
              </li>
              <li>
                <b>Printable menu support.</b> Show the same menus on paper by
                using our print menu feature.
              </li>
            </ul>
          </Col>
        </Row>
      </section>
      <section>
        <Row>
          <Col md={4}>
            <h2>How it works</h2>
          </Col>
          <Col md={8}>
            <p className="lead">
              Taking advantage of this integration is really simple. Here's how:
            </p>
            <ol>
              <li>
                <b>Get an account.</b> You'll need a Taplist.io account on our{" "}
                <b>Pro</b> plan. We are famous for our friendly support,
                no-contract pricing, and great service.{" "}
                <a href="https://taplist.io/signup">Get started now</a>.
              </li>
              <li>
                <b>Configure the integration in the dashboard.</b> Visit the{" "}
                <a href="https://app.taplist.io/dash/menu">menu editor</a> and
                tap the <em>Start POS Sync</em> button at the bottom.
              </li>
            </ol>
            <p>
              For additional steps and help, see the{" "}
              <a href="https://help.taplist.io/article/87-gotab-guide">
                GoTab and Taplist.io Integration Guide
              </a>{" "}
              in our Help Center.
            </p>
          </Col>
        </Row>
      </section>
      <section>
        <Row>
          <Col md={4}>
            <h2>FAQ</h2>
          </Col>
          <Col md={8}>
            <FAQ
              faqItems={[
                {
                  question: "Does this integration cost extra?",
                  answer: (
                    <>
                      No, this integration is currently included at no extra
                      cost on our <b>Pro</b> plan. GoTab does not charge extra
                      for this integration.
                    </>
                  ),
                },
                {
                  question: "Does Taplist.io modify my GoTab data?",
                  answer: (
                    <>
                      No, the integration does not change any data on your GoTab
                      account. You can think of it as "one-way sync": we read
                      your menu data, but we never alter it.
                    </>
                  ),
                },
                {
                  question: "How many TVs can I install?",
                  answer: (
                    <>
                      <p>
                        There's no limit! Taplist.io supports managing as many
                        TVs as you like. Many of our customers use multiple TVs.
                        You can configure TVs to all show the same menu, or show
                        different things on different TVs.
                      </p>
                      <p>
                        In some cases we charge an additional monthly fee for
                        additional activated TVs. See our transparent pricing
                        page for detail.
                      </p>
                    </>
                  ),
                },
                {
                  question: "I have an additional question about this.",
                  answer: (
                    <>
                      We'd love to hear from you! Please send a note to{" "}
                      <b>support@taplist.io</b> and a friendly human will get
                      right back to you.
                    </>
                  ),
                },
              ]}
            />
          </Col>
        </Row>
      </section>
    </Layout>
  );
};

export default GoTabIntegration;
