import React from "react";
import { Accordion, Card } from "react-bootstrap";

export default function FAQ({ faqItems }) {
  const cards = faqItems.map((faqItem, i) => {
    return (
      <Card key={`item-${i}`}>
        <Accordion.Toggle as={Card.Header} eventKey={`${i}`} style={{ cursor: 'pointer'}}>
          <b>{faqItem.question}</b>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={`${i}`}>
          <Card.Body>{faqItem.answer}</Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  });
  return <Accordion>{cards}</Accordion>;
}
